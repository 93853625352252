<template>
<div class="search">
    <div class="target-search align-center">
        <van-search v-model="keywords" placeholder="请输入搜索关键词" show-action  @search="onSearch" @cancel="onCancel" style="width:100%" />
    </div>
               <van-list
        v-model:loading="loading"
        :finished="finished"
        :finished-text="total > 6 || fleatotal  > 6 ? '没有更多了':''"
        @load="onload"
        v-if="show"
        >
    <van-tabs v-model:active="active" sticky  @click="onChange"  border="false" tab-class="tab-class" nav-class="nav-class" tab-active-class="tab-active-class" class="tabs" >

        <van-tab title="跳蚤市场" name="1">
            <div  class="about abouts">共找到{{$handle.tranNumber( fleatotal,3)}}个相关内容</div>
             <NoData v-if="fleatotal == 0" :bottomBg="true"></NoData>
            <div class="market-list" v-else>   
                <div class="market-list-items">
                 
                    <flea :fleaData="fleaList"></flea>
                </div>
            </div>
        </van-tab>
      <van-tab title="悬赏问答" name="0">
        <div class="reward-list">
          <div class="about">共找到{{$handle.tranNumber( total,3)}}个相关内容</div>

          <bounty :bountyData="communityList" type="lb"></bounty>
        </div>
      </van-tab>
      <van-tab title="资源互助" name="2">
        <div class="reward-list">
          <div class="about">共找到{{$handle.tranNumber( eletotal,3)}}个相关内容</div>

          <resources :electronData="eleList" type="lb"></resources>
        </div>
      </van-tab>
    </van-tabs>
      </van-list>
</div>
</template>

<script>
import NoData from "components/NoData";
import UserInfo from "components/userInfo";
import bounty from "../../../components/bounty"
import flea from "../../../components/flea"
import resources from "../../../components/resources"
export default {
  name: "sign",
  data(){
    return{
        active:'1',
        keywords:'',
        communityList:[],
        page:1,
        load:true,
        fleaList:[],
        fleapage:1,
        fleaload:true,
        show:false,
        history:[],
        total:0,
        fleatotal:0,
      eletotal:0,
      eleList:[],
      elepage:1,
      eleload:true,
            loading: false,
            finished: true,
      act:''
    }
  },

    created() {
// this.getcommunityList()
      if (this.$route.query.act) {
        this.act = this.$route.query.act
      }
  },
  methods:{
    geteleList(){
      let url = this.$api.communityCommunitySearch + '?pageSize=10&model_type=electron&page=' + this.elepage + '&keyword=' + this.keywords
      this.$http.get(url, false).then(res => {
        if(res.data.success){
          this.loading = false;
          this.eletotal=res.data.data.total
          if(res.data.data.data.length){
            this.finished = false;
            this.eleList=this.eleList.concat(res.data.data.data)

            this.elepage+=1
          }else{
            this.eleload=false
            this.finished = true;
          }
        }else{
          this.eleload=false
          this.eletotal= 0
        }
      })
    },
 getcommunityList(){
        let url = this.$api.communityCommunitySearch + '?pageSize=10&model_type=bounty&page=' + this.page + '&keyword=' + this.keywords 
        this.$http.get(url, false).then(res => {
          if(res.data.success){
                   this.loading = false;
                   this.total=res.data.data.total
            if(res.data.data.data.length){
                this.finished = false;
              this.communityList=this.communityList.concat(res.data.data.data)
                
                this.page+=1
            }else{ 
              this.load=false
               this.finished = true;
            }
          }else{
            this.load=false
            this.total= 0
          }
        })
      },
      getfleaList(){
        let url = this.$api.communityCommunitySearch + '?pageSize=6&model_type=flea&page=' + this.fleapage + '&keyword=' + this.keywords
        this.$http.get(url, false).then(res => {
          if(res.data.success){
               this.loading = false;
               this.fleatotal=res.data.data.total
            if(res.data.data.data.length){
                  this.finished = false;
              this.fleaList=this.fleaList.concat(res.data.data.data)
                this.fleapage=this.fleapage + 1
                
            }else{
              this.fleaload=false
                 this.finished = true;
            }
          }else{
             this.fleaload=false
            this.fleatotal= 0
          }
        })
      },
   onChange(name,title) {
      this.active = name
      this.act = name
          this.communityList=[]
            this.page=1
            this.load=true
            this.fleaList=[]
            this.fleapage=1
            this.fleaload=true
     this.eleList=[]
     this.elepage=1
     this.eleload=true
            this.show=true
             this.loading= false
            this.finished= true
      if(this.active == 0 ){
                this.getcommunityList()
            }  else if(this.active == 1 ){
                this.getfleaList()
            }   else if(this.active == 2){
              this.geteleList()
            }
      },
      onSearch() {
        this.communityList=[]
            this.page=1
            this.load=true
            this.fleaList=[]
            this.fleapage=1
            this.fleaload=true
        this.eleList=[]
        this.elepage=1
        this.eleload=true
            this.show=true
             this.loading= false
            this.finished= true
        this.active = this.act
    if(this.active == 0 ){
                this.getcommunityList()
            }  else if(this.active == 1 ){
                this.getfleaList()
            }  else if(this.active == 2){
      this.geteleList()
    }
      },
      onCancel(){
        this.$router.go(-1)
      },
  onload(){
            if(this.active == 0 && this.load){
                this.getcommunityList()
            }  else if(this.active == 1 && this.fleaload){
                this.getfleaList()
            } else if(this.active == 2 && this.eleload){
              this.geteleList()
            }
        },
  },
  components:{
    UserInfo,
    bounty,
    flea,
    NoData,
    resources
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/community/search.scss";
</style>
<style lang="scss">
$r: 750/16/1rem;
.van-search__content{
  border-radius: 20px;
}
.search .van-tabs__wrap{
    position: fixed;
    width: 100%;
    top: 182/$r;
    z-index: 99;
  }
 .search  .target-search{
     position: fixed;
     width: 100%;
     top: 84/$r;
     z-index: 99;
 }
</style>